import { getAction, getByIdAction, postAction, putAction, deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/xunTourPlan'


const listtourplanApi = (params) => getAction(baseURL + "/list", params);

const addtourplanApi = (params) => postAction(baseURL + "/add", params);

const edittourplanApi = (params) => putAction(baseURL + "/edit", params);

const deletetourplanByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);

const selecttourplanByIdApi = (params) => getByIdAction(baseURL + '/selectById', params);

export {
    selecttourplanByIdApi,
    deletetourplanByIdApi,
    edittourplanApi,
    addtourplanApi,
    listtourplanApi
}
