<template>
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="40%"
    >
      <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
        <el-row :gutter="24">
          <el-col :md="12" :sm="12">
            <el-form-item label="主题" prop="titile">
              <el-input
                placeholder="请输入主题"
                v-model="addEditForm.titile"
                prop="destinationCountry"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="12">
            <el-form-item label="是否国外">
              <el-switch
                v-model="addEditForm.isCountry"
                active-color="#ff4949"
                inactive-color="#13ce66"
                active-text="否"
                inactive-text="是"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="12" :sm="12">
            <el-form-item label="地区" prop="planData">
              <el-cascader
                v-model="areaValue"
                :options="areaList"
                :props="{
                  value: 'areaCode',
                  label: 'areaName',
                  checkStrictly: true,
                }"
                @change="handleChange"
                filterable
                ref="cascader"
                clearable
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="12">
            <el-form-item label="目的地址" prop="destinationAddress">
              <el-input
                placeholder="请输入目的地址"
                v-model="addEditForm.destinationAddress"
                prop="destinationAddress"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="12" :sm="12">
            <el-form-item label="计划时间" prop="times">
              <el-input
                placeholder="请输入计划时间"
                v-model="addEditForm.times"
                prop="times"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="12">
            <el-form-item label="最佳时间" prop="bestTime">
              <el-input
                placeholder="请输入最佳时间"
                v-model="addEditForm.bestTime"
                prop="bestTime"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="6" :sm="6">
            <el-form-item label="预算范围">
              <el-input
                type="number"
                placeholder="最小值"
                v-model="addEditForm.budgetMin"
              />
            </el-form-item>
          </el-col>
          <el-col :md="2" :sm="2">
            <el-form-item
              label-width="0"
              style="display: flex; justify-content: space-between"
            >
              --
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="4">
            <el-form-item label-width="0">
              <el-input
                type="number"
                placeholder="最大值"
                v-model="addEditForm.budgetMax"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="24" :sm="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                placeholder="请输入备注"
                v-model="addEditForm.remark"
                prop="remark"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <!-- @click="$refs.drawer.closeDrawer()" -->
        <el-button type="primary" @click="addFormButton()" :loading="loading">{{
          loading ? "提交中 ..." : "确 定"
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  selecttourplanByIdApi,
  deletetourplanByIdApi,
  edittourplanApi,
  addtourplanApi,
  listtourplanApi,
} from "@/api/life/tour/tourplan";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {},
      timer: null,
      ids: null,
      areaValue: [],
      areaList: [],
    };
  },
  methods: {
    show(id, type) {
      this.dialog = true;
      if (type == 2) {
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
      }
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          // this.loading = true;
          this.cancelForm();
          this.dialog = false;
          // this.timer = setTimeout(() => {
          //   done();
          //   // 动画关闭需要一定的时间
          //   // setTimeout(() => {
          // this.loading = false;
          //   // }, 400);
          // }, 2000);
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      if (this.addEditForm.id == undefined || this.addEditForm.id == null) {
        this.addEditForm.id;
        addtourplanApi(this.addEditForm).then((res) => {
          this.cancelForm();
        });
      } else {
        edittourplanApi(this.addEditForm).then((res) => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton() {
      selecttourplanByIdApi(this.ids).then((res) => {
        this.addEditForm = res.result;
      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    handleChange() {},
  },
};
</script>
<style scoped>
</style>



